import {inject, Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {GetBusySlotApiAdapter} from "@module/slot/adapter/external/api/get.busy.slot.api.adapter";
import {BusySlotsActions} from "@module/slot/state";
import {BusySlotsResponseDto} from "@module/slot/domain/dto/busy-slots.response.dto";

export type ISpecialistState = {
    items: BusySlotsResponseDto[];
    temporary: BusySlotsResponseDto[];
};

@State<ISpecialistState>({
    name: 'busySlots',
    defaults: {
        temporary: [],
        items: [],
    },
})
@Injectable()
export class BusySlotsState {

    private readonly getBusySlotApiAdapter = inject(GetBusySlotApiAdapter);

    @Action(BusySlotsActions.GetList)
    public async getList(ctx: StateContext<ISpecialistState>, {payload}: BusySlotsActions.GetList): Promise<void> {
        return this.getBusySlotApiAdapter.executeAsync(payload).then((items) => {
            ctx.patchState({items});
        });
    }

    // Selectors

    @Selector()
    public static items(state: ISpecialistState) {
        return state.items;
    }
}
