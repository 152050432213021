import {Types} from "@utility/types";

export namespace BusySlotsActions {
  export class GetList {
    public static readonly type = '[BusySlots API] Get List';
    public constructor(
        public readonly payload: {
            start: Types.StringISO;
            end: Types.StringISO;
            specialistIds?: Types.StringObjectId[];
        },
    ) {
    }
  }
}
